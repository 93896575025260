// Insert the following code at the specified position

export default {
  artists: [
    "Leonardo da Vinci",
    "Vincent van Gogh",
    "Pablo Picasso",
    "Claude Monet",
    "Michelangelo",
    "Rembrandt",
    "Salvador Dali",
    "Edgar Degas",
    "Henri Matisse",
    "Georgia O'Keeffe",
    "Gustav Klimt",
    "Edvard Munch",
    "Johannes Vermeer",
    "Diego Rivera",
    "Frida Kahlo",
    "Marc Chagall",
    "Jackson Pollock",
    "Andy Warhol",
    "Willem de Kooning",
    "Paul Cezanne",
    "Henri Rousseau",
    "Georges Seurat",
    "Mary Cassatt",
    "Camille Pissarro",
    "Rene Magritte",
    "Wassily Kandinsky",
    "Banksy",
    "Ansel Adams",
    "David Hockney",
    "Roy Lichtenstein",
    "Keith Haring",
    "Lucian Freud",
    "Francis Bacon",
    "Jeff Koons",
    "Mark Rothko",
    "Richard Serra",
    "Joan Miro",
    "Marcel Duchamp",
    "Hans Arp",
    "Piet Mondrian",
    "Robert Rauschenberg",
    "Cy Twombly",
    "Chuck Close",
    "Gerhard Richter",
    "Cindy Sherman",
    "Damien Hirst",
    "Yayoi Kusama",
    "Ai Weiwei",
    "Kara Walker",
    "Takashi Murakami",
    "Yoshitaka Amano",
  ],
  artStyles: [
    "Abstract Expressionism",
    "Art Deco",
    "Art Nouveau",
    "Baroque",
    "Bauhaus",
    "Cloisonnism",
    "Conceptual Art",
    "Cubism",
    "Dada",
    "De Stijl",
    "Expressionism",
    "Fauvism",
    "Futurism",
    "Gothic",
    "Impressionism",
    "Kinetic Art",
    "Mannerism",
    "Minimalism",
    "Naive Art",
    "Neoclassicism",
    "Op Art",
    "Performance Art",
    "Pointillism",
    "Pop Art",
    "Post-Impressionism",
    "Pre-Raphaelite",
    "Realism",
    "Rococo",
    "Romanesque",
    "Surrealism",
    "Symbolism",
    "Tonalism",
    "Abstract Art",
    "Art Brut",
    "Art Informel",
    "Ashcan School",
    "Biomorphism",
    "Color Field",
    "Constructivism",
    "Contemporary Art",
    "Environmental Art",
    "Hard Edge Painting",
    "Happenings",
    "Installation Art",
    "Lyrical Abstraction",
    "Magical Realism",
    "Neo-Expressionism",
    "New Objectivity",
    "Post-Minimalism",
    "Social Realism",
    "Suprematism",
  ],
  artMediums: [
    "Acrylic paint",
    "Oil paint",
    "Watercolor",
    "Gouache",
    "Ink",
    "Charcoal",
    "Graphite",
    "Colored pencil",
    "Pastel",
    "Marker",
    "Pen",
    "Pencil",
    "Encaustic",
    "Tempera",
    "Spray paint",
    "Airbrush",
    "Digital art",
    "Mixed media",
    "Collage",
    "Assemblage",
    "Sculpture",
    "Clay",
    "Metal",
    "Wood",
    "Stone",
    "Glass",
    "Ceramic",
    "Textile",
    "Fiber",
    "Paper",
    "Photography",
    "Printmaking",
    "Etching",
    "Lithography",
    "Screenprinting",
    "Woodcut",
    "Linocut",
    "Intaglio",
    "Monotype",
    "Bookmaking",
    "Calligraphy",
    "Graffiti",
    "Mosaic",
    "Stained glass",
    "Installation",
    "Performance art",
    "Video art",
    "Sound art",
    "Light art",
    "Land art",
  ],
  colorPallets: [
    "Monochromatic",
    "Analogous",
    "Complementary",
    "Split complementary",
    "Triadic",
    "Tetradic",
    "Warm",
    "Cool",
    "Neutral",
    "Black and white",
    "Pastel",
    "Bright",
    "Muted",
    "Jewel tones",
    "Earthy",
    "Primary",
    "Secondary",
    "Tertiary",
    "Rainbow",
    "Gradient",
    "Ombre",
    "Geometric",
    "Striped",
    "Polka dot",
    "Checkerboard",
    "Herringbone",
    "Plaid",
    "Tartan",
    "Gingham",
    "Floral",
    "Abstract",
    "Minimalist",
    "Rustic",
    "Industrial",
    "Vintage",
    "Modern",
    "Contemporary",
    "Futuristic",
    "Gothic",
    "Bohemian",
    "Nautical",
    "Tropical",
    "Desert",
    "Mountain",
    "Forest",
    "Ocean",
    "Sky",
    "Sunset",
    "Rainbow",
    "Pastel sunset",
    "technicolor",
    "psychedelic",
  ],
};
